import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from '../../components/common/Button';
import InputGroup from '../common/InputGroup';
//import InputGroupHp from '../common/InputGroupHp';
import ProgressModal from '../modal/ProgressModal';
import ModalPortal from '../../ModalPortal';
import axios from "axios";
import * as FCS from '../../common/js/common_spc';
import { ApiContext } from '../../Context';

const year = (new Date()).getFullYear();
const YEAR_SELECT = Array.from(new Array(100), (val, index) => year - index);
const MONTH_SELECT = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const DAY_SELECT = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];


function EnterForm() {
    const context = useContext(ApiContext);
    const USER_API = context.url;

    const intialValues = { id: "", pwd1: "", pwd2: "", email: "", phone: "", name: "", birth: "0000-00-00", birthYear: "0000", birthMonth: "00", birthDay: "00", age14: "", agree1:"", agree2:"", agree3:"", agree_optional:"", emailAuthNo: "", phoneAuthNo: "" }; 
    const [show, setShow] = useState(false); //인증메일창 보이는지 여부
    const [show2, setShow2] = useState(false); //인증메일창 보이는지 여부
    const [formValues, setFormValues] = useState(intialValues);  //사용자가 입력 필드에 입력 한 데이터를 보유
    const [formErrors, setFormErrors] = useState({}); //각 입력 필드에 대한 오류를 보유
    const [formSuccess, setFormSuccess] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false); //양식이 제출 중인지 여부. 이는 양식에 오류가 없는 경우에만 해당됩
    //const [required, setRequired] = useState({email: false, phone: false});  
   // const [selectedDialCode, setSelectedDialCode] = useState('');
    
    
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isValidPhone, setIsValidPhone] = useState(false);
    const [isValidEmailAuthNo, setIsValidEmailAuthNo] = useState(false);  
    const [emailAuthIdx, setEmailAuthIdx] = useState('');
    const [isValidPhoneAuthNo, setIsValidPhoneAuthNo] = useState(false);  
    const [phoneAuthIdx, setPhoneAuthIdx] = useState('');
    const [modalProgress, setModalProgress] = useState(false);
    const [isKorea, setIsKorea] = useState(true);

    const [fullscreen, setFullscreen] = useState(true);
    const [isShowAgree2, setIsShowAgree2] = useState(false);
    const [isShowAgreeOptional, setIsShowAgreeOptional] = useState(false);

    const enterMemberWithAuth = async() => {
        
        setModalProgress(true);
        let birth = `${formValues.birthYear}-${formValues.birthMonth}-${formValues.birthDay}`;
        //let age_check = (formValues.age14) ? 'Y' : 'N';
        let terms_agree = (formValues.agree1) ? 'Y' : 'N';
        let personal_agree = (formValues.agree2) ? 'Y' : 'N';
        let mailing_agree = (formValues.agree3) ? 'Y' : 'N';
        let optional_agree = (formValues.agree_optional) ? 'Y' : 'N';
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'enterMemberWithAuth',
            id: formValues.id,
            pw: formValues.pwd2,
            name: formValues.name,
            email: formValues.email,
            email_auth_idx: emailAuthIdx,
            phone: formValues.phone,
            phone_auth_idx: phoneAuthIdx,
            birthday : birth,
            terms_agree: terms_agree,
            personal_agree: personal_agree,
            mailing_agree: mailing_agree,
            optional_agree: optional_agree,
        });
        
        if(response.data.success===true){
            //성공하면.. 가입완료화면 보이도록
            setFormValues({id: formValues.id, name: formValues.name}); //초기화            
            setIsSubmitting(true); //회원가입 성공시
            setModalProgress(false);
        }else{
            //가입 실패시
            setModalProgress(false);
            alert(response.data.error_msg);
        }
    }
  
    const handleBlur = (e) => {
        
        const { name, checked } = e.target;
        //const { name, value } = e.target;
        if (name === 'id'){
            checkValidId();
        }
        if (name === 'pwd1'){
            checkValidPwd1();
        }
        if (name === 'pwd2'){
            checkValidPwd2();
        }
        if(name === 'name'){
            checkValidName();
        }
        if (name === 'phone'){
            if(!isValidPhoneAuthNo){
                checkValidPhone();
            }
        }
        if (name === 'email'){
            if(!isValidEmailAuthNo){
                checkValidEmail();
            }
        }
        
        if(name === 'age14'){
            if(checked===true){
                 checkValidBirth();
            }
        }
        //if(formValues.age14===true){
            if(name === 'birthYear'){ 
                //if(checked ==='age14'){            
                checkValidBirth();
                //}
            }
            if(name === 'birthMonth'){
                checkValidBirth();
            }
            if(name === 'birthDay'){
                checkValidBirth();
            }   
       // }
        
    };
    
    const handleChange = useCallback(e => {
        const { name, value, checked } = e.target;
        if(name==='agree1' || name==='agree2' || name==='agree3' || name==='age14' || name==='agree_optional' ){
            setFormValues({ ...formValues, [name]: checked });    
        }else{
            setFormValues({ ...formValues, [name]: value });
        }

    }, [formValues]);



    const handleCheckedAll = useCallback((checked) => {
            if (checked) {
                setFormValues({ ...formValues, agree1: true, agree2: true, agree3: true, age14: true, agree_optional: true });    
            } else {
                setFormValues({ ...formValues, agree1: false, agree2: false, agree3: false, age14: false, agree_optional: false });    
            }

    }, [formValues]);
    
    const submitForm = (e) => {
        
        enterMemberWithAuth(); 
        e.preventDefault();
        e.stopPropagation();
    };
    const handleSubmit = (e) => {
        
        const form = e.currentTarget;

        if(isKorea && !formValues.phone && !formValues.email){
            formErrors.phone = "휴대폰 또는 이메일 중 1개는 필수 항목입니다.";
            formErrors.email = "휴대폰 또는 이메일 중 1개는 필수 항목입니다.";
        }
    
        if (form.checkValidity() === false) {   
            
            e.preventDefault();
            e.stopPropagation();
            form.classList.add('was-validated');
            //alert('no pass!')            
            setFormErrors(validate(formValues));    //Error state 값 갱신      
           //  setIsSubmitting(true); //회원가입 성공시, 테스트용
           
        }else{

            const totError = Object.keys(formErrors).map(function(key) {
                let cnt = 0;
                if(formErrors[key].length>0){
                    cnt ++;
                }
                return cnt;
            }).reduce((a,b) => a+b, 0);
            //alert(totError)
            if(totError<1) {

                {/*
                        if(isValidEmail){              
                
                            if(isValidEmailAuthNo){
                
                                if(isValidPhone){
                
                                    if(isValidPhoneAuthNo){
                
                                        submitForm(e);
                                    }else{
                                        alert('휴대폰 인증이 필요합니다. 인증번호 발송 버튼을 눌러주세요.');
                                        e.preventDefault();
                                        e.stopPropagation();       
                                        //checkValidEmailAuthNo();
                                        //checkValidPhone
                                    }
                                }else{
                                    alert('휴대폰 번호를 정확히 입력해주세요.');
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                            }else{
                                alert('이메일 인증이 필요합니다. 인증메일 발송 버튼을 눌러주세요.');
                                e.preventDefault();
                                e.stopPropagation();       
                                checkValidEmailAuthNo();
                            }
                        }else{
                            alert('이메일을 정확히 입력해주세요.');
                            e.preventDefault();
                            e.stopPropagation();
                        }
                */}
                let isPhone = false;
                let isEmail = false;
                
                if( isKorea ){
                    if(isValidPhone){
                        if(isValidPhoneAuthNo){
                            isPhone = true;
                        }else{
                            alert('휴대폰 인증이 필요합니다. 인증번호 발송 버튼을 눌러주세요.');
                            e.preventDefault();
                            e.stopPropagation();       
                        }
                    }else{
                        isPhone = true;
                    }
                }else{
                    isPhone = true;
                }

                if(isValidEmail){              
                    if(isValidEmailAuthNo){
                        isEmail = true;
                    }else{
                        alert('이메일 인증이 필요합니다. 인증메일 발송 버튼을 눌러주세요.');
                        e.preventDefault();
                        e.stopPropagation();       
                        checkValidEmailAuthNo();
                    }
                }else{
                    isEmail = true;
                }

                if(isPhone && isEmail){
                    submitForm(e);
                } 
            
            }else{
                
                if(isValidEmail && !isValidEmailAuthNo){
                    alert('이메일 인증 확인이 필요합니다.');
                }
                else if(isValidPhone && !isValidPhoneAuthNo && isKorea){
                    alert('휴대폰 인증 확인이 필요합니다.');
                }
                else{
                    alert('입력한 항목을 확인해주세요.');
                }
                e.preventDefault();
                e.stopPropagation();
            }      
        }
    };

    const validate = (values) => { //submit일때만 체크
        let errors = {};
        // 아이디 값이 없을시
        if (!values.id) {
            errors.id = "필수 항목입니다.";
        } else if (FCS.regex_ID(values.id) === false) {
            errors.id = "아이디 형식이 유효하지 않습니다.";
        }else{
            errors.id = "";
        }
        checkValidId();

        // 비밀번호 값이 없을시
        if (!values.pwd1) {
            errors.pwd1 = "필수 항목입니다.";
        } else if (FCS.regex_PW(values.pwd1) === false) {
            errors.pwd1 = "비밀번호 형식이 유효하지 않습니다.";
        }
        checkValidPwd1();

        if (!values.pwd2) {
            errors.pwd2 = "필수 항목입니다.";
        } else if (FCS.regex_PW(values.pwd2) === false) {
            errors.pwd2 = "비밀번호 형식이 유효하지 않습니다.";
        }
        checkValidPwd2();

        // 이름 값이 없을시
        if (!values.name) {
            errors.name = "필수 항목입니다.";
        } else if (FCS.regex_Name(values.name) === false) {
            errors.name = "이름 형식이 유효하지 않습니다.";
        }
        checkValidName();

        // 휴대폰번호 값이 없을시
        if(isKorea)
        {    
            if(!values.phone && !values.email){
                errors.phone = "휴대폰 또는 이메일 중 1개는 필수 항목입니다.";
                errors.email = "휴대폰 또는 이메일 중 1개는 필수 항목입니다.";
            }else{

                if(values.phone){
                    if (FCS.regex_Mail(values.phone) === false) {
                            errors.phone = "휴대폰번호 형식이 유효하지 않습니다.";
                    }
                    if(!isValidPhoneAuthNo){
                        checkValidPhone();
                    }
                }
                // 이메일 값이 없을시
                if(values.email){
                    if (FCS.regex_Mail(values.email) === false) {
                        errors.email = "이메일 형식이 유효하지 않습니다.";
                    }
                    if(!isValidEmailAuthNo){
                        checkValidEmail();
                    }    
                }
            }
            
        }else{
            if (!values.email) {
                errors.email = "이메일은 필수 항목입니다.";
            } else if (FCS.regex_Mail(values.email) === false) {
                errors.email = "이메일 형식이 유효하지 않습니다.";
            }
            if(!isValidEmailAuthNo){
                checkValidEmail();
            }
        }
    
        // 출생년도 값이 없을시
        if(values.age14===true){
            let birth = `${values.birthYear}-${values.birthMonth}-${values.birthDay}`;
       
            if(birth==='0000-00-00'){
                errors.birth = "";    
            }else{
                if(values.birthYear==='0000'){
                    errors.birth = "생년월일을 정확히 입력해주세요."
                }else if(values.birthMonth==='00'){
                    errors.birth = "생년월일을 정확히 입력해주세요."
                }else if(values.birthDay==='00'){
                    errors.birth = "생년월일을 정확히 입력해주세요."
                }else if (FCS.check_Birthday(birth) === false) {
                    errors.birth = "생년월일을 정확히 입력해주세요.";
                }else{
                    errors.birth = ""; 
                }
            }
            checkValidBirth();
        }else{
            errors.birth = "";
        }
        return errors;
    };
    const checkValidId = () => {
        let el = document.getElementById('id');   
        if (!el.value) {
            el.classList.add('is-invalid');       
            formErrors.id = "필수 항목입니다.";            
        } else if (FCS.regex_ID(el.value) === false) {
            el.classList.add('is-invalid');
            formErrors.id = "아이디는 4~12자의 영문 소문자와 숫자,특수기호는 - 또는 _ 만 사용할 수 있습니다.";
        } else {
            //api 중복확인
            el.classList.remove('is-invalid'); 
            formErrors.id = "";
            checkDuplicateID();
        }
        feedBackErrorMsg('id', formErrors.id);
    }
    const checkValidPwd1 = () => {
        let el = document.getElementById('pwd1');      
        if (!el.value) {
            el.classList.add('is-invalid');       
            formErrors.pwd1 = "필수 항목입니다.";
        } else if (FCS.regex_PW(el.value) === false) {
            el.classList.add('is-invalid');
            formErrors.pwd1 = "8-16자의 영문자+숫자+특수문자(예: @ # ...)를 모두 사용해야 합니다.";
        } else {
            el.classList.remove('is-invalid'); 
            el.classList.add('is-valid');      
            formErrors.pwd1 = "";     
            formSuccess.pwd1 = "안전한 비밀번호입니다.";     
        }
        feedBackErrorMsg('pwd1', formErrors.pwd1);
    }
    const checkValidPwd2 = () => {
        let el = document.getElementById('pwd2');
        let el_pwd1 = document.getElementById('pwd1');
        if (!el.value) {
            el.classList.add('is-invalid');       
            formErrors.pwd2 = "필수 항목입니다.";
        } else if (FCS.regex_PW(el.value) === false) {
            el.classList.add('is-invalid');
            formErrors.pwd2 = "비밀번호 형식이 유효하지 않습니다.";         
        } else {
            if(el.value!==el_pwd1.value){
                el.classList.add('is-invalid');
                formErrors.pwd2 = "비밀번호가 일치하지 않습니다.";
            }else{
                el.classList.remove('is-invalid');
                el.classList.add('is-valid');       
                formErrors.pwd2 = "";
                formSuccess.pwd2 = "안전한 비밀번호입니다.";     
            }
        }
        feedBackErrorMsg('pwd2', formErrors.pwd2);
    }
    const checkValidName = () => {
        let el = document.getElementById('name');        
        if (!el.value) {
            el.classList.add('is-invalid');       
            formErrors.name = "필수 항목입니다.";
        } else if (FCS.regex_Name(el.value) === false) {
            el.classList.add('is-invalid');
            formErrors.name = "이름 형식이 유효하지 않습니다.";
        } else {
            el.classList.remove('is-invalid');       
            el.classList.add('is-valid');       
            formErrors.name = "";
            formSuccess.name = "사용가능합니다.";  
        }
        feedBackErrorMsg('name', formErrors.name);
    }
    const checkValidBirth = () => {
        //if(formValues.age14===true)
        //{
            let el_yy = document.getElementById('birthYear');
            let el_mm = document.getElementById('birthMonth');
            let el_dd = document.getElementById('birthDay');
            const el_birth = `${el_yy.value}-${el_mm.value}-${el_dd.value}`;
            if(el_birth==='0000-00-00')
            {
                formErrors.birth = "";
                formSuccess.birth = "사용가능합니다.";
            }
            else
            {
                if (el_mm.value.length === 1) {
                    el_mm = "0" + el_mm;
                }
                if (el_dd.value.length === 1) {
                    el_dd = "0" + el_dd;
                }
            
                if (FCS.check_Birthday(el_birth) === false) {
                    formErrors.birth = "생년월일을 정확히 입력해주세요!";
                } else {

                    if (el_yy.value==='0000') {
                        el_yy.classList.add('is-invalid');       
                        formErrors.birth = "태어난 년도를 선택하세요.";
                    }
                    else if (el_mm.value==='00') {
                        el_mm.classList.add('is-invalid');       
                        formErrors.birth = "태어난 월을 선택하세요.";                
                    }
                    else if (el_dd.value==='00') {
                        el_dd.classList.add('is-invalid');       
                        formErrors.birth = "태어난 일(날짜)를 선택하세요.";
                    }
                    else
                    {
                        el_yy.classList.remove('is-invalid');
                        el_mm.classList.remove('is-invalid');
                        el_dd.classList.remove('is-invalid');
                        formErrors.birth = "";
                        formSuccess.birth = "사용가능합니다.";
                    }
                }
            }
            feedBackErrorMsg('birth', formErrors.birth);
        //}
    }

    const checkValidEmail = () => {
        let el = document.getElementById('email');
        
        if (!el.value) {
            if(!formErrors.phone && formSuccess.phone){
                el.classList.remove('is-invalid');
                el.classList.add('is-valid');
                formErrors.email = "";
                formErrors.error_email_state = "";
                formSuccess.email = "";  
                setIsValidEmail(false);
                feedBackSuccessMsg('email', formSuccess.email);
                
            }else{
                el.classList.add('is-invalid');       
                if(isKorea){
                    formErrors.email = "휴대폰 또는 이메일 중 1개는 필수 항목입니다.";
                    formErrors.error_email_state = "";
                }else{
                    formErrors.email = "이메일은 필수 항목입니다.";
                    formErrors.error_email_state = "";
                }
                
                formSuccess.email = "";
                setIsValidEmail(false);
                feedBackSuccessMsg('email', formSuccess.email);
            }

        } else if (FCS.regex_Mail(el.value) === false) {
            el.classList.add('is-invalid');
            formErrors.email = "이메일 형식이 유효하지 않습니다.";
            setIsValidEmail(false);
        } else {
            //api 중복확인
            el.classList.remove('is-invalid');
            el.classList.add('is-valid');       
            formErrors.email = "";
           // formSuccess.email = "{checkValidEmail}사용가능한 이메일 입니다.";  
            checkDuplicateEMAIL();
        }
        feedBackErrorMsg('email', formErrors.email);
    }
    const checkValidPhone = () => {
        let el = document.getElementById('phone');

        if (!el.value) {
            
            if(!formErrors.email && formSuccess.email){
                el.classList.remove('is-invalid');
                el.classList.add('is-valid');
                formErrors.phone = "";
                formErrors.error_phone_state = "";
                formSuccess.phone = "";  
                setIsValidPhone(false);
                feedBackSuccessMsg('phone', formSuccess.phone);
                
            }else{
                el.classList.add('is-invalid');
                formErrors.phone = "휴대폰 또는 이메일 중 1개는 필수 항목입니다.";
                formErrors.error_phone_state = "";
                formSuccess.phone = "";
                setIsValidPhone(false);
                feedBackSuccessMsg('phone', formSuccess.phone);
            }
         
        } else if (FCS.regex_Phone(el.value) === false) {
    
            el.classList.add('is-invalid');
            formErrors.phone = "휴대폰 형식이 유효하지 않습니다.";
            setIsValidPhone(false);
        } else {
            //api 중복확인
            el.classList.remove('is-invalid');
            el.classList.add('is-valid');       
            formErrors.phone = "";
           
            checkDuplicatePHONE();
        }
        feedBackErrorMsg('phone', formErrors.phone);
    }

    const checkValidEmailAuthNo = () => {
        if(!show){
            document.getElementById('email').classList.add('is-invalid');   
            formErrors.email = "인증메일 발송 버튼을 눌러주세요.";
            feedBackErrorMsg('email', formErrors.email);
            
        }else{
            let el = document.getElementById('emailAuthNo');
            if (!el.value) {
                el.classList.add('is-invalid');       
                formErrors.emailAuthNo = "필수 항목입니다.";
                feedBackErrorMsg('emailAuthNo', formErrors.emailAuthNo);
                setIsValidEmailAuthNo(false);
            } else if (FCS.regex_AuthNo(el.value) === false) {
                el.classList.add('is-invalid');
                formErrors.emailAuthNo = "인증번호 형식이 유효하지 않습니다.";
                feedBackErrorMsg('emailAuthNo', formErrors.emailAuthNo);
                setIsValidEmailAuthNo(false);
            } else {
                //api 중복확인
            }
        }
    }
    const feedBackErrorMsg = (values, msg) => {
        setFormErrors({ ...formErrors, [values]: msg});
    }
    const feedBackSuccessMsg = (values, msg) => {
        setFormSuccess({ ...formSuccess, [values]: msg});
    }
    const checkDuplicateID = async() => {
        let el = document.getElementById('id');
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'checkDuplicateID',
            user_id: formValues.id
        });
        
        if(response.data.success===true){
            if(response.data.nothing===true){
                el.classList.remove('is-invalid'); 
                el.classList.add('is-valid');       
                formErrors.id = "";
                formErrors.error_code = "";
                formSuccess.id = "사용가능한 아이디입니다.";
                feedBackErrorMsg('id', formErrors.id);
                feedBackSuccessMsg('id', formSuccess.id);
            }else{
                el.classList.add('is-invalid');       
                formErrors.id = response.data.state;
                formErrors.error_code = response.data.code; //1006:탈퇴한 회원
                feedBackErrorMsg('id', formErrors.id);
             //   setIsValidId(false);
            }
        }else{
            el.classList.add('is-invalid');       
            formErrors.id = response.data.error_msg;
            formErrors.error_code = response.data.code;
            feedBackErrorMsg('id', formErrors.id);
        }
    }
    const checkDuplicateEMAIL = async() => {
        let el = document.getElementById('email');
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'checkDuplicateEmail',
            email: formValues.email
        });
        
        if(response.data.success===true){
            if(response.data.nothing===true){
                el.classList.remove('is-invalid'); 
                el.classList.add('is-valid');       
                formErrors.email = "";
                formErrors.error_email_state = "";
                formSuccess.email = "사용가능한 이메일입니다.";
                feedBackErrorMsg('email', formErrors.email);
                feedBackSuccessMsg('email', formSuccess.email);
                setIsValidEmail(true);
                
                if(isKorea){
                    if(!document.getElementById('phone').value && formErrors.phone){
                        formErrors.phone = "";
                        feedBackErrorMsg('phone', formErrors.phone);
                    }
                }
                //setRequired({ ...required, phone: false});
            }else{
                el.classList.add('is-invalid');       
                formErrors.email = "이미 존재하는 이메일입니다.";
                formErrors.error_email_state = response.data.state; //사용 중 | 휴면상태
                feedBackErrorMsg('email', formErrors.email);
                setIsValidEmail(false);
                //setRequired({ ...required, email: true});
            }
        }else{
            el.classList.add('is-invalid');       
            formErrors.email = response.data.error_msg;
            feedBackErrorMsg('email', formErrors.email);
        }
    }
    const checkDuplicatePHONE = async() => {
        let el = document.getElementById('phone');
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'checkDuplicatePhone',
            phone: formValues.phone
        });
        
        if(response.data.success===true){
            if(response.data.nothing===true){
                el.classList.remove('is-invalid'); 
                el.classList.add('is-valid');       
                formErrors.phone = "";
                formErrors.error_phone_state = "";
                formSuccess.phone = "사용가능한 휴대폰번호입니다.";
                feedBackErrorMsg('phone', formErrors.phone);
                feedBackSuccessMsg('phone', formSuccess.phone);
                setIsValidPhone(true);      

                if(!document.getElementById('email').value && formErrors.email){
                    formErrors.email = "";
                    feedBackErrorMsg('email', formErrors.email);
                }
                //setRequired({ ...required, email: false});         
            }else{
                el.classList.add('is-invalid');       
                formErrors.phone = "이미 존재하는 휴대폰번호입니다.";
                formErrors.error_phone_state = response.data.state; //사용 중 | 휴면상태
                feedBackErrorMsg('phone', formErrors.phone);
                setIsValidPhone(false);
                //setRequired({ ...required, phone: true});
            }
        }else{
            el.classList.add('is-invalid');       
            formErrors.phone = response.data.error_msg;
            feedBackErrorMsg('phone', formErrors.phone);
        }
    }
    // 폼태그에 값이 0 이거나, isSubmitting 이 false 상태일 때,
    //submitForm을 누르면 [formErrors]가 마운트 되도록!
    
    
    const sendMail = async() => {
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'sendMail_AuthNo',
            email: formValues.email,
            akind: 'Enter'
        });
        
        if(response.data.success===true){
            document.getElementById('emailAuthNo').classList.remove('is-invalid');       
            formErrors.emailAuthNo = "메일 확인 후 인증번호를 입력해주세요.";
            feedBackErrorMsg('emailAuthNo', formErrors.emailAuthNo);
                   
        }else{
            document.getElementById('emailAuthNo').classList.add('is-invalid');       
            formErrors.emailAuthNo = response.data.error_msg;
            feedBackErrorMsg('emailAuthNo', formErrors.emailAuthNo);
        }
    }
    const sendMailClick = () => {
        //document.getElementById('emailAuthNo').value = '';
        setFormValues({ ...formValues, emailAuthNo: "" });
        sendMail();
    };  
    const onEmailBtnClick = () => {
        if(isValidEmail){
            setShow(!show);
            //인증메일발송 영역 활성화
            document.getElementById('email').classList.remove('is-invalid');   
            formErrors.email = "";
            feedBackErrorMsg('email', formErrors.email);
            //인증메일발송버튼 안보이도록
            document.getElementById('emailAuthBtn').style.display="none";   
            //인증메일발송
            sendMail();          
        }
    };
    const confirmEmailAuthNoClick = async() => {
        setModalProgress(true);
        let el = document.getElementById('emailAuthNo');
       // alert(formValues.AuthNo)
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'confirmMail_AuthNo',
            email: formValues.email,
            auth_no: formValues.emailAuthNo,
            akind: 'Enter'
        });
        
        if(response.data.success===true){
            el.classList.remove('is-invalid');  
            el.classList.add('is-valid');     
            formErrors.emailAuthNo = "";
            formSuccess.email = "인증되었습니다."; 
           // feedBackErrorMsg('AuthNo', formErrors.AuthNo);
            setIsValidEmailAuthNo(true);               
            setEmailAuthIdx(response.data.auth_idx);
            setShow(false);
            setModalProgress(false);
        }else{
            el.classList.add('is-invalid');       
            //formErrors.emailAuthNo = "인증번호를 다시 확인해주세요.";
            formErrors.emailAuthNo = response.data.error_msg;
            feedBackErrorMsg('emailAuthNo', formErrors.emailAuthNo);
            setIsValidEmailAuthNo(false);
            setModalProgress(false);
        }
    };

    //인증번호관련함수
    const sendSMS = async() => {
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'sendSMS_AuthNo',
            phone: formValues.phone,
            akind: 'Enter'
        });
        
        if(response.data.success===true){
            document.getElementById('phoneAuthNo').classList.remove('is-invalid');       
            formErrors.phoneAuthNo = "문자 확인 후 인증번호를 입력해주세요";
            feedBackErrorMsg('phoneAuthNo', formErrors.phoneAuthNo);
            //  setIsValidAuthNumber(true);               
        }else{
            document.getElementById('phoneAuthNo').classList.add('is-invalid');       
            formErrors.phoneAuthNo = response.data.error_msg;
            feedBackErrorMsg('phoneAuthNo', formErrors.phoneAuthNo);
        }
    }
    const sendSMSClick = () => {
        setFormValues({ ...formValues, phoneAuthNo: "" });
        sendSMS();
    };  
    const onSMSBtnClick = () => {
 
        if(isValidPhone){
            setShow2(!show2);
            //인증메일발송 영역 활성화
            document.getElementById('phone').classList.remove('is-invalid');   
            formErrors.phone = "";
            feedBackErrorMsg('phone', formErrors.phone);
            //인증메일발송버튼 안보이도록
            document.getElementById('phoneAuthBtn').style.display="none";   
            //인증메일발송
            sendSMS();          
        }
    };
    const confirmSMSAuthNoClick = async() => {
        if(document.getElementById('phoneAuthNo').value.length===0){
            document.getElementById('phoneAuthNo').classList.add('is-invalid');   
            formErrors.phone = "인증번호를 입력해주세요.";
            feedBackErrorMsg('phoneAuthNo', formErrors.phone);
            
        }else{
            setModalProgress(true);
            let el = document.getElementById('phoneAuthNo');
        // alert(formValues.authNumber)
            //let USER_API = "https://userapi.godpeople.com/";
            const response = await axios.post(USER_API,{
                task: 'confirmSMS_AuthNo',
                phone: formValues.phone,
                auth_no: formValues.phoneAuthNo,
                akind: 'Enter'
            });
            
            if(response.data.success===true){
                el.classList.remove('is-invalid');  
                el.classList.add('is-valid');     
                formErrors.phoneAuthNo = "";
                formSuccess.phone = "인증되었습니다.";             
                setIsValidPhoneAuthNo(true);               
                setPhoneAuthIdx(response.data.auth_idx);
                setShow2(false);
                setModalProgress(false);
            }else{
                el.classList.add('is-invalid');       
                //formErrors.phoneAuthNo = "인증번호를 다시 확인해주세요";
                formErrors.phoneAuthNo = response.data.error_msg;
                feedBackErrorMsg('phoneAuthNo', formErrors.phoneAuthNo);
                setIsValidPhoneAuthNo(false); 
                setModalProgress(false);
            }
        }
    };

    const checkIsKorea = async() => {
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'isKorea',
        });
        if(response.data.success===true){
            if(response.data.isKorea===true){
                setIsKorea(true);
            }
        }
        //setIsKorea(false);
    }

    const handleLogin = () => {
        if(localStorage.getItem('rurl')){
            window.location.href = "/login?rurl="+localStorage.getItem('rurl');
        }else{
            window.location.href = "/login";
        }
        setTimeout(localStorage.removeItem('rurl'),1000); //로컬스토리지 rurl 삭제
    }

    function handleShowAgree2() {
        setFullscreen(true);
        setIsShowAgree2(true);
        //listMailing();
    }
    
    function handleShowAgreeOptional() {
        setFullscreen(true);
        setIsShowAgreeOptional(true);
        //listMailing();
    }


    useEffect(() => {
        //국내확인
        checkIsKorea();
       // setSelectedDialCode('82')
    },[]);

    useEffect(() => {
        if(!isSubmitting){
            
            if(formErrors.id){
                document.getElementById('id').classList.add('is-invalid');
            }else{
                document.getElementById('id').classList.remove('is-invalid');
            }

            if(formErrors.pwd1){
                document.getElementById('pwd1').classList.add('is-invalid');
            }else{
                document.getElementById('pwd1').classList.remove('is-invalid');
            }
            
            if(formErrors.pwd2){
                document.getElementById('pwd2').classList.add('is-invalid');
            }else{
                document.getElementById('pwd2').classList.remove('is-invalid');
            }

            if(formErrors.name){
                document.getElementById('name').classList.add('is-invalid');
            }else{
                document.getElementById('name').classList.remove('is-invalid');
            }
            if(isKorea){
                if(formErrors.phone){
                    document.getElementById('phone').classList.add('is-invalid');
                }else{
                    document.getElementById('phone').classList.remove('is-invalid');
                }
            }

            if(formErrors.email){
                document.getElementById('email').classList.add('is-invalid');
            }else{
                document.getElementById('email').classList.remove('is-invalid');
            }
        }
      
    }, [formErrors, isSubmitting]);
    return (
        <>
        {isSubmitting ? (
           
            <>
                <div className="row mb-1 mt-5">
                    <div className="col-sm-12 text-center text-primary cfs-20">
                        하나님이 사랑하시는 <span className="cfs-20 font-weight-bold">{formValues.name}</span>님<br />
                        회원가입을 환영합니다.
                    </div>
                    <div className="col-sm-12 text-center mt-5 mb-2">
                        <img src="/img/img_me_b.png" className="ic_enter_s" alt="enter_success_img" />
                    </div>

                </div>

                <div className="row mb-5 mt-4">
                    <div className="col-sm-12 text-center cfs-18">
                        가입한 아이디는 <span className="text-primary">{formValues.id}</span> 입니다. <br />
                        <div className="pt-2 col-sm-12 text-center cfs-14">
                        <span className="text-primary">갓피플, 갓피플성경, 갓피플몰(쇼핑)</span> 등<br />
                        크리스천 신앙생활에 유익한 서비스를 마음껏<br />
                        이용할 수 있습니다. 
                        </div>
                    </div>
                </div>
                    
                <button type="button" className="btn btn-primary bp-3 w-100 d-block mt-4 mx-auto cfs-18" onClick={handleLogin}>로그인</button>

            </>
        ):(
            <form className="g-3 needs-validation" noValidate onSubmit={handleSubmit}>
                <div className="row mb-1">
                    <InputGroup name="id" title="아이디" type="text" placeholder="아이디" formValue={formValues.id} formError={formErrors.id} formSuccess={formSuccess.id} handleChange={handleChange} handleBlur={handleBlur} required={true} />
                    
                    { formErrors.error_code===1006 &&  <div className="invalid-feedback d-block">다른 아이디를 입력해주세요. 탈퇴된 아이디는 재사용할 수 없습니다.</div>}
                </div>               
                {/*<button onClick={checkDuplicateID}>중복검사</button>*/}
                <div className="row mb-1">
                    <InputGroup name="pwd1" title="비밀번호" type="password" placeholder="비밀번호 (8~16자리)" formValue={formValues.pwd1} formError={formErrors.pwd1} formSuccess={formSuccess.pwd1} handleChange={handleChange} handleBlur={handleBlur} required={true} />
                   
                </div>
                <div className="row mb-1">
                    <div className="col-sm-12">
                        <input
                        type="password"
                        className="form-control pt-2 pb-2"
                        id="pwd2"
                        name="pwd2"
                        placeholder="비밀번호 확인"
                        value={formValues.pwd2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        />
                        { formErrors.pwd2 ? (
                            <div className="invalid-feedback">{formErrors.pwd2}</div>
                        ):(
                            <div className="valid-feedback">{formSuccess.pwd2}</div>
                        )}
                    </div>
                </div>


                <div className="row mb-1">
                    <InputGroup name="name" title="이름" type="text" placeholder="이름" formValue={formValues.name} formError={formErrors.name} formSuccess={formSuccess.name} handleChange={handleChange} handleBlur={handleBlur} required={true} />
                </div>


                <label className="col-form-label label_text pb-0">생년월일 <span className="text-info cfs-14">(선택)</span></label>
                <div className="row">
                    <div className="col-4 mb-1 pe-0">
                        <select className="form-select cf-select invalid" aria-label="Default select example" id="birthYear" name="birthYear" value={formValues.birthYear}
                        onChange={handleChange}
                        onBlur={handleBlur} required>
                        <option value="0000">출생년도</option>  
                        {
                        YEAR_SELECT.map((year, idx) => {
                            return <option key={idx} value={year}>{year}년</option>
                        })
                        }
                        </select>
                        
                    </div>
                    <div className="col-4 ps-2 pe-2">
                        <select className="form-select cf-select invalid" aria-label="Default select example" id="birthMonth" name="birthMonth" value={formValues.birthMonth}
                        onChange={handleChange}
                        onBlur={handleBlur} required>
                        <option value="00">월</option>
                        {
                        MONTH_SELECT.map((month, idx) => {
                            return <option key={idx} value={month}>{month}월</option>
                        })
                        }      
                        </select>
                    </div>
                    <div className="col-4 ps-0">
                        <select className="form-select cf-select invalid" aria-label="Default select example" id="birthDay" name="birthDay" value={formValues.birthDay}
                        onChange={handleChange}
                        onBlur={handleBlur} required>
                        <option value="00">일</option>
                        {
                        DAY_SELECT.map((day, idx) => {
                            return <option key={idx} value={day}>{day}일</option>
                        })
                        } 
                        </select>
                    </div>
                </div>   
                
                <div className="row col-12">
                    { formErrors.birth ? (
                        <>
                        <span className="is-invalid"></span>
                        <div className="invalid-feedback birth">{formErrors.birth}</div>
                        </>
                    ):(
                        <div className="valid-feedback birth">{formSuccess.birth}</div>
                    )}
                </div>    

            { isKorea &&
                <>
                <div className="row mb-2 mt-4">
                    <div className="d-flex justify-content-center">
                        <span className="d-block w-100 bg-light text-dark-gray cfs-15 pt-2 pb-2 text-center">휴대폰 또는 이메일 인증이 필요합니다. <br /></span>
                    </div>
                    
                </div>
                <div className="row mb-1">
                    
                    {/*<InputGroup name="phone" title="휴대폰" type="tel" placeholder="휴대폰 번호 입력" formValue={formValues.phone} formError={formErrors.phone} formSuccess={formSuccess.phone} handleChange={handleChange} handleBlur={handleBlur} required={required.phone} />*/}

                    <InputGroup name="phone" title="휴대폰" type="tel" placeholder="휴대폰 번호 입력" formValue={formValues.phone} formError={formErrors.phone} formSuccess={formSuccess.phone} handleChange={handleChange} handleBlur={handleBlur} required={false} />

                    { formErrors.phone && formErrors.error_phone_state==='휴면상태' &&  <div className="invalid-feedback d-block">휴면계정에 있는 정보입니다. 로그인 후 이용할 수 있습니다. <Link to="/findId"><span className="text-danger">아이디찾기{'>'}</span></Link></div>}

                </div>
                <div className="row mb-1 px-5">    
                    <div className="col-sm-12">
                        <div className="d-flex justify-content-center">
                            
                            <button type="button" id="phoneAuthBtn" className="btn btn-primary mt-2 bpx-5 bp-3" onClick={onSMSBtnClick}>
                            인증번호 발송
                            </button>
                            
                        </div>
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="invalid-feedback" id="fail-msg">{formErrors.fail_msg}</div>
                </div>

                    {show2 ? (
                    <div className="mb-3 pt-2 px-3 py-4 pb-2 bg_auth">
                        <div className="row mb-1">
                            <p className="mt-2">입력한 휴대폰 번호로 인증번호가 발송되었습니다.<br/>발송된 6자리 인증번호를 입력해 주세요.</p>
                            
                            <InputGroup name="phoneAuthNo" title="인증번호 입력" type="text" placeholder="인증번호 (6자리)" formValue={formValues.phoneAuthNo} formError={formErrors.phoneAuthNo} formSuccess={formSuccess.phoneAuthNo} handleChange={handleChange} handleBlur={handleBlur} />
                        </div>
                        <div className="row mb-1">    
                            <div className="col-sm-12">
                                <div className="d-flex justify-content-center">
                                    <button type="button" className="btn btn-primary mt-2" onClick={confirmSMSAuthNoClick}>
                                    인증확인
                                    </button>
                                </div>
                                <div className="mt-2 mb-1 cfs-12 text-center">인증번호가 오지 않았다면? <u onClick={sendSMSClick} role="button">재발송 요청</u></div>
                            </div>
                        </div>
                    </div>
                    ):(
                        null
                    )}
                </>
            }


                <div className="row mb-1">

                    { isKorea ? (
                        <InputGroup name="email" title="이메일" type="email" placeholder="이메일 주소 입력" formValue={formValues.email} formError={formErrors.email} formSuccess={formSuccess.email} handleChange={handleChange} handleBlur={handleBlur} required={false} />
                    ):(
                        <InputGroup name="email" title="이메일" type="email" placeholder="이메일 주소 입력" formValue={formValues.email} formError={formErrors.email} formSuccess={formSuccess.email} handleChange={handleChange} handleBlur={handleBlur} required={true} />
                    )}
                    
                    { formErrors.email && formErrors.error_email_state==='휴면상태' &&  <div className="invalid-feedback d-block">휴면계정에 있는 정보입니다. 로그인 후 이용할 수 있습니다. <Link to="/findId"><span className="text-danger">아이디찾기{'>'}</span></Link></div>}
                   
                </div>
                <div className="row mb-1">    
                    <div className="col-sm-12">
                        <div className="d-flex justify-content-center">
                            <button type="button" id="emailAuthBtn" className="btn btn-primary mt-2 bpx-5 bp-3" data-bs-toggle="modal" data-bs-target="#emailModal" onClick={onEmailBtnClick}>
                            인증메일 발송
                            </button>
                        </div>
                    </div>
                </div>

                {show ? (
                <div className="mb-2 pt-2 px-3 py-4 pb-2 bg_auth">
                    <div className="row mb-1">
                        <p className="mt-2">입력한 메일 주소로 인증번호가 발송되었습니다.<br/>발송된 6자리 인증번호를 입력해 주세요.</p>
                        <InputGroup name="emailAuthNo" title="인증번호 입력" type="text" placeholder="인증번호 (6자리)" formValue={formValues.emailAuthNo} formError={formErrors.emailAuthNo} formSuccess={formSuccess.emailAuthNo} handleChange={handleChange} handleBlur={handleBlur} />
                    </div>
                    <div className="row mb-1">    
                        <div className="col-sm-12">
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn btn-primary mt-2" onClick={confirmEmailAuthNoClick}>
                                인증확인
                                </button>
                            </div>
                            <div className="mt-2 mb-1 cfs-12 text-center">인증번호가 오지 않았다면? <u onClick={sendMailClick} role="button">재발송 요청</u></div>
                        </div>
                        
                    </div>
                </div>
                ):(
                    null
                )}

                <div className="row mb-1 mt-3 pt-0">
                    <div className="col-12">
                        <div className="form-check check_agree_wrap">
                            <input className="form-check-input" type="checkbox" id="agree_all" name="agree_all" value={formValues.agree_all} onChange={(e) => handleCheckedAll(e.target.checked)} role="button" />
                            <label className="form-check-label cfs-14" htmlFor="agree_all" role="button">
                            <span className="text-black">전체 동의합니다.</span>
                            </label>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="form-check check_agree_wrap">
                            <input className="form-check-input" type="checkbox" id="agree1" name="agree1" value={formValues.agree1} onChange={handleChange} onBlur={handleBlur} required role="button" checked={ (formValues.agree1) ? "checked":""} />
                            <label className="form-check-label cfs-14" htmlFor="agree1" role="button">
                            <span className="text-danger">[필수]</span> 갓피플 이용약관에 동의합니다.
                            </label>
                            <span className="guide_view cfs-12" onClick={FCS.openPolicyTerms}><u role="button">보기</u></span>
                            <div className="invalid-feedback">
                            - 필수 항목에 모두 동의해주세요
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-check check_agree_wrap">
                            <input className="form-check-input" type="checkbox" id="agree2" name="agree2" value={formValues.agree2} onChange={handleChange} onBlur={handleBlur} required role="button" checked={ (formValues.agree2) ? "checked":""} />
                            <label className="form-check-label cfs-14" htmlFor="agree2" role="button">
                            <span className="text-danger">[필수]</span> 개인정보 수집 및 이용에 동의합니다. 
                            </label>
                            <span className="guide_view cfs-12"><u onClick={handleShowAgree2} role="button">보기</u></span>
                            <div className="invalid-feedback">
                            - 필수 항목에 모두 동의해주세요
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-check check_agree_wrap">
                            <input className="form-check-input" type="checkbox" id="age14" name="age14" value={formValues.age14} onChange={handleChange} onBlur={handleBlur} required role="button" checked={ (formValues.age14) ? "checked":""} />
                            <label className="form-check-label cfs-14" htmlFor="age14" role="button">
                            <span className="text-danger">[필수]</span> 14세 이상입니다.
                            </label>
                            <div className="invalid-feedback">
                            - 필수 항목에 모두 동의해주세요
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-12">
                        <div className="form-check check_agree_wrap">
                            <input className="form-check-input" type="checkbox" id="agree_optional" name="agree_optional" value={formValues.agree_optional} onChange={handleChange} onBlur={handleBlur} role="button" checked={ (formValues.agree_optional) ? "checked":""} />
                            <label className="form-check-label cfs-14" htmlFor="agree_optional" role="button">
                            [선택] 개인정보 수집 및 이용에 동의합니다.
                            </label>
                            <span className="guide_view cfs-12"><u onClick={handleShowAgreeOptional} role="button">보기</u></span>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="form-check check_agree_wrap">
                            <input className="form-check-input" type="checkbox" id="agree3" name="agree3" value={formValues.agree3} onChange={handleChange} onBlur={handleBlur} role="button" checked={ (formValues.agree3) ? "checked":""} />
                            <label className="form-check-label cfs-14" htmlFor="agree3" role="button">
                            [선택] 크리스천을 위한 광고성 메일링 수신 동의
                            </label>
                        </div>
                        <div className="form-check mt-1">
                            <div className="cfs-12 form_text text-info">갓피플에서 제공하는 오늘의 묵상 컨텐츠와 신앙도서, 성경책, 교회용품, 전도용품 할인 정보 와 이벤트 내용을 보내드립니다.</div>
                        </div>
                    </div>
                </div>                  
                <Button title="가입하기" />
            </form>
        )}


        <Modal show={isShowAgree2} fullscreen={fullscreen} onHide={() => setIsShowAgree2(false)} aria-labelledby="contained-modal-title-vcenter" dialogClassName="modal-50w" centered>
            <Modal.Header closeButton className="mx-2">
                <Modal.Title className="cfs-18">[필수] 개인정보 수집 및 이용 동의</Modal.Title>
            </Modal.Header>
         
            <Modal.Body className="mx-2 my-0">
                <table className="table table-bordered table-area agree-tbl">

                <thead>
                    <tr>
                        <th scope="row" className="cfs-13">목적</th>
                        <th scope="row" className="cfs-13">항목</th>
                        <th scope="row" className="cfs-13">보유기간</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="cfs-13 text-secondary">이용자 식별, 중복 가입 및 부정 이용 방지</td>
                    <td className="cfs-13 text-secondary w-30"><strong className="title1">[필수]</strong> 아이디, 비밀번호, 이름, 이메일 또는 휴대폰번호</td>
                    <td className="cfs-13 text-secondary w-30" rowSpan="3">회원 탈퇴시 3일 보관 후 파기 또는 법령에 따른 보존 기간</td>
                </tr>
                <tr>
                    <td className="cfs-13 text-secondary">아이디, 비밀번호 찾기 시 본인 확인, 고객문의 대응 및 이용 안내</td>
                    <td className="cfs-13 text-secondary w-30"><strong className="title1">[필수]</strong> 이메일 또는 휴대폰번호</td>  
                </tr>
                <tr>
                    <td className="cfs-13 text-secondary">서비스 응대 및 이용 기록 관리</td>
                    <td className="cfs-13 text-secondary w-30"><strong className="title1">[필수]</strong> IP Address, 쿠키, 방문일시, OS 종류, 기기정보, 브라우저 종류, 서비스 이용 기록</td>
                    
                </tr>
                <tr>
                    <td className="cfs-13 text-secondary">부정행위 적발시 처리 및 관리</td>
                    <td className="cfs-13 text-secondary w-30"><strong className="title1">[필수]</strong> 아이디, 이름, 이메일 또는 휴대폰번호, IP주소, 쿠키, 기기정보</td>
                    <td className="cfs-13 text-secondary w-30">5년간 보관 후 파기</td>
                </tr>
                </tbody>
                </table>
                <div><span className="cfs-13">개인정보 수집을 거부할 수 있으나 회원가입 및 로그인 기반 서비스를 이용 할 수 없습니다</span></div>
            </Modal.Body>
        </Modal>

        <Modal show={isShowAgreeOptional} fullscreen={fullscreen} onHide={() => setIsShowAgreeOptional(false)} aria-labelledby="contained-modal-title-vcenter" dialogClassName="modal-50w" centered>
            <Modal.Header closeButton className="mx-2">
                <Modal.Title className="cfs-18">[선택] 개인정보 수집 및 이용 동의</Modal.Title>
            </Modal.Header>
         
            <Modal.Body className="mx-2 my-0">
                <table className="table table-bordered table-area agree-tbl">
                <thead>
                    <tr>
                        <th scope="row" className="cfs-13">목적</th>
                        <th scope="row" className="cfs-13">항목</th>
                        <th scope="row" className="cfs-13">보유기간</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="cfs-13 text-secondary">맞춤 컨텐츠 및 서비스 제공용<br />(생일축하 서비스, 연령에 따른 컨텐츠 등 제공)</td>
                    <td className="cfs-13 text-secondary w-30"><strong className="title1">[선택]</strong> 생년월일</td>
                    <td className="cfs-13 text-secondary w-30" rowSpan="3">회원 탈퇴시 3일 보관 후 파기 또는 법령에 따른 보존 기간</td>
                </tr>
               
                </tbody>
                </table>
                <div><span className="cfs-13">개인정보 수집을 거부할 수 있으나 해당 서비스 혜택에 제한이 있을 수 있습니다.</span></div>
            </Modal.Body>
        </Modal>

        { modalProgress && ( 
            <ModalPortal>
                <ProgressModal />
            </ModalPortal>
        )}
        </>
    );
  }
  
  export default EnterForm;